export function textLengthLimit(perex: string, limit = 750): string {
	if (perex.length <= limit) {
		return perex;
	}

	let text = "";

	let currentWord = "";
	for (let index = 0; index < perex.length; index++) {
		const character = perex[index] ?? "";
		if (/\s/u.exec(character) !== null || index === perex.length - 1) {
			const textToAdd = `${currentWord}${character}`;
			if (text.length + textToAdd.length > limit) {
				break;
			}
			text += textToAdd;
			currentWord = "";
			continue;
		}

		currentWord += character;
	}

	return `${text.trim()}…`;
}
